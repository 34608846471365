import './NotFound.css';

function NotFound() {
  return (
    <div className='Assigner-center-container'>
      <header className='Assigner-center Assigner-header'>
        <h1>404</h1>
        <p>Nie odnaleziono zasobu.</p>
      </header>
    </div>
  );
}

export default NotFound;
